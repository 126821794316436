import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import {
    playBtn
} from '@/images/support/index'
import useNavigate from '@/hooks/useNavigate'
import { useGTM } from '@/hooks/useGTM.js'

const VideoBox = ({ video_link, go_link }) => {

    const goLink = () => {
        if (!!go_link) {
            useNavigate(go_link)

            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: 20075
                }
            });
            useGTM('home_click', 'gif', "XREAL BEAM PRO", 'home promotion')

        }
    }
    return (
        <div className={style.video} onClick={goLink} style={{ cursor: !!go_link ? 'pointer' : "auto" }}>

            <video autoPlay playsInline loop muted width="100%" >
                <source src={video_link} type="video/mp4" />
            </video>
        </div>
    )
}

export default VideoBox