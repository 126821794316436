import React from "react";
import style from './index.module.less'
import comcss from "../index.module.less"
import useBigScreen from "@/hooks/bigScreen"
import LearnBuy from "../learn-buy"
import { useIntl } from "react-intl";

const Index = ({pro_intro}) => {
    const {direction , background,background_mobile,pro_logo,text_id,learn_link,buy_link,theme,pro_name,sale_shop}=pro_intro
    const bigScreen = useBigScreen(768)
    const intl=useIntl()
    return (
        <>
            <div className={`${style.block} ${comcss.section}`} style={{ backgroundImage: bigScreen ? `url(${background})` : `url(${background_mobile})`,justifyContent:direction=='left'?'flex-start':'flex-end' }}>
                {!!sale_shop&& 
                <div className={style.sale}>{intl.formatMessage({ id: "sale_text", defaultMessage: "SALE" })}</div>}
               
                <LearnBuy theme={theme} icon={pro_logo} textid={text_id} learnLink={learn_link} buylink={buy_link} proName={pro_name} sale_shop={sale_shop}/>
            </div>
        </>
    )
}

export default Index