import React, { useEffect } from "react";
import style from './index.module.less'
import { XButton } from '@/components/index'
import useNavigate from '@/hooks/useNavigate'
import { useIntl } from "react-intl"
import comcss from "../index.module.less"
import useGa from '@/hooks/useGa'
import getCountry from '@/utils/getCountry.js'
// import SaleLogo from '@/images/icon/saleLogo.svg'
import {SaleLogo} from '@/components/index'
import { buy_link } from '@/scripts/constant.js'
import { useInView } from "react-intersection-observer"

const Index = ({ learnLink, buylink, icon, textid, theme, proName ,sale_shop}) => {
    const intl = useIntl()
    const region = getCountry()
    const [btnRef , btnRefInview] = useInView({ threshold: 0 });
    useEffect(()=>{
        if(!!btnRefInview){
            typeof sensors !== "undefined" &&  sensors.track('module_view', {
                custom: {
                    module_id: 10007,
                    products_name: proName
                }
            });
        }
    },[btnRefInview])
    const learnmore = () => {
        useGa("Home", `home_${proName}_learn_more`, "Click")
        typeof sensors !== "undefined" &&   sensors.track('module_interaction', {
            custom: {
                module_id: 10007,
                products_name: proName
            }
        });
        if (proName == 'air2ultra') {
            window.open(learnLink)
        } else {
            useNavigate(learnLink)
        }

    }
    const buynow = () => {
        useGa("Home", `home_${proName}_buy`, "Click")
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 10008,
                products_name: proName
            }
        });
        if (proName == 'sticker' || proName == 'air2ultra') {
            window.open(buylink)
        } else {
            useNavigate(buylink)
        }

    }
    return (
        <div className={style.block} style={{ width: proName == 'sticker' ? '100%' : '40%' }}>
            {region == 'jp' && proName == 'air2ultra' &&
                <div className={style.jpUltra}>企業開発者向け</div>
            }

            {icon}
            <h2 className={comcss.desc} style={{ color: `${theme == "black" ? "rgba(0,0,0,0.8)" : "#fff"}` }}>{intl.formatMessage({ id: `${textid}` })}</h2>
            <div className={style.blockBtns} ref={btnRef}>
                {learnLink && <XButton onClick={learnmore}
                    width="160px"
                    size="smallest"
                    theme={theme}>{intl.formatMessage({ id: "learn_more" })}</XButton>}
                {buylink && <XButton onClick={buynow}
                    width="160px"
                    size="smallest"
                    type='secondary'
                    theme={theme}
                    >
                        {!!sale_shop&& <SaleLogo text={buy_link[region]['air'].sale_shop_reduce}/>}
                       
                        {intl.formatMessage({ id: "buy_now" })}</XButton>}
            </div>
        </div>
    )
}

export default Index