const ces_bg=`${process.env.resourceUrl}images/ces/ces_bg.jpg`
const ces_news1=`${process.env.resourceUrl}images/ces/ces_new1.webp`
const ces_news2=`${process.env.resourceUrl}images/ces/ces_new2.webp`
const ces_news3=`${process.env.resourceUrl}images/ces/ces_new3.webp`
const ces_text=`${process.env.resourceUrl}images/ces/ces_text.svg`
const home_ultra_pc = `${process.env.resourceUrl}images/ces/home_ultra_pc.webp`
const home_ultra_pc_left = `${process.env.resourceUrl}images/ces/home_ultra_pc_left.webp`
const home_ultra_mobile = `${process.env.resourceUrl}images/ces/home_ultra_mobile.webp`
const home_beam_mobile = `${process.env.resourceUrl}images/ces/home_beam_mobile.webp`
const home_beam_pc = `${process.env.resourceUrl}images/ces/home_beam.webp`
import Arrow_up from '@/images/SVG/arrow-up.svg'
import Arrow_down from '@/images/SVG/arrow-down.svg'

export{home_beam_pc,
    home_beam_mobile,
    home_ultra_mobile,
    home_ultra_pc_left,
    home_ultra_pc,
    ces_bg,
    ces_news1,
    ces_news2,
    ces_news3,
    ces_text,
    Arrow_up,
    Arrow_down
}