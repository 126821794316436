const oneForAll = `${process.env.resourceUrl}images/ar/home_1forall.webp`
const home_air2_mobile = `${process.env.resourceUrl}images/ar/home_air2_product.jpg`
const home_eva = `${process.env.resourceUrl}images/ar/home_EVA.webp`
const home_ada = `${process.env.resourceUrl}images/ar/home_ADA.webp`
const stickersVd = `${process.env.resourceUrl}video/home_stickers.mp4`
const light_question_pc = `${process.env.resourceUrl}images/home/light/light_question_pc.webp`
const light_question_mobile = `${process.env.resourceUrl}images/home/light/light_question_mobile.webp`


const jp_love_text = `${process.env.resourceUrl}images/home/love/love_text.webp`
const jp_love_girl = `${process.env.resourceUrl}images/home/love/love_girl.webp`
const jp_love_boy = `${process.env.resourceUrl}images/home/love/love_boy.webp`
const jp_love_boy_mobile = `${process.env.resourceUrl}images/home/love/love_boy_mobile.webp`
const air2_pink_logo = `${process.env.resourceUrl}images/home/love/air2_pink_logo.webp`

const home_beampro = `${process.env.resourceUrl}images/home/beampro_pc.jpg`
const home_beampro_mobile = `${process.env.resourceUrl}images/home/beampro_mobile.jpg`
const home_air2ultra = `${process.env.resourceUrl}images/home/air2ultra_pc.jpg`
const home_air2ultra_mobile  = `${process.env.resourceUrl}images/home/air2ultra_mobile.jpg`
const home_air2pro = `${process.env.resourceUrl}images/home/air2pro_pc.jpg`
const home_air2pro_mobile  = `${process.env.resourceUrl}images/home/air2pro_mobile.jpg`
const air_pc = `${process.env.resourceUrl}images/home/air_pc.jpg`
const air_mobile  = `${process.env.resourceUrl}images/home/air_mobile.jpg`
const beam_pc = `${process.env.resourceUrl}images/home/beam_pc.jpg`
const beam_mobile  = `${process.env.resourceUrl}images/home/beam_mobile.jpg`
const adapter_pc = `${process.env.resourceUrl}images/home/adapter_pc.webp`
const adapter_mobile  = `${process.env.resourceUrl}images/home/adapter_mobile.webp`
const home_hub = `${process.env.resourceUrl}images/home/hub_pc.webp`
const home_hub_mobile  = `${process.env.resourceUrl}images/home/hub_mobile.webp`
const home_video = `${process.env.resourceUrl}video/home_video1.mp4`
const home_video2 = `${process.env.resourceUrl}video/home_video2.mp4`
const jpoff_bg = `${process.env.resourceUrl}images/home/jp_15offbg_pc.jpg`
const jpoff_bg_mobile = `${process.env.resourceUrl}images/home/jp_15offbg_mobile.jpg`
export {
    jpoff_bg,
    jpoff_bg_mobile,
    air_pc,
    air_mobile,
    beam_pc,
    beam_mobile,
    adapter_pc,
    adapter_mobile,
    home_hub,
    home_hub_mobile,
    home_air2pro,
    home_air2pro_mobile,
    home_air2ultra,
    home_air2ultra_mobile,
    home_beampro_mobile,
    home_video,
    home_eva,
    stickersVd,
    home_ada,
    home_air2_mobile,
    oneForAll,
    light_question_pc,
    light_question_mobile,
    jp_love_text,
    jp_love_girl,
    jp_love_boy,
    air2_pink_logo,
    jp_love_boy_mobile,
    home_beampro,
    home_video2
}