import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import useNavigate from '@/hooks/useNavigate'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GrowthBook, GrowthBookProvider, useFeatureIsOn } from "@growthbook/growthbook-react";
import AmazonIcon from '@/images/icon/amazon.png'
import getCountry from "@/utils/getCountry.js"
import { generateNonce, generateSign } from "@/utils/helperFn"

const growthbook = new GrowthBook({
    apiHost: process.env.ABtest_url,
    clientKey: process.env.ABtest_key,
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
        console.log("Viewed Experiment", {
            experimentId: experiment.key,
            variationId: result.key
        });
    }
});

const ABTest = ({ intro }) => {
    const region = getCountry()
    let userRegion = ''
    if (region == 'base') {
        userRegion = 'GLOBAL'
    } else {
        userRegion = region.toUpperCase()
    }
    useEffect(() => {

        let abtest_userID = ''
        if (!window.localStorage.getItem('ABTest_userID')) {
            abtest_userID = generateUserId()
            window.localStorage.setItem("ABTest_userID", abtest_userID)
            growthbook.setAttributes({
                "UserId": abtest_userID,
                "Region": userRegion
            });
            console.log(1)
        } else {
            abtest_userID = window.localStorage.getItem('ABTest_userID')
            growthbook.setAttributes({
                "UserId": abtest_userID,
                "Region": userRegion
            });
            console.log(2)

        }

        growthbook.loadFeatures();
       



        generateUserId()
    }, []);

    const generateUserId = () => {
        const nonce = generateNonce(32);
        const deviceInfo = navigator.userAgent + screen.width + screen.height;
        const userID = `${nonce}${hashString(deviceInfo)}`;

        return userID
    }
    function hashString(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return Math.abs(hash).toString();
    }

    return (
        <GrowthBookProvider growthbook={growthbook} >
            <ProBox intro={intro} />
        </GrowthBookProvider>
    );
}

const ProBox = ({ intro }) => {
    gsap.registerPlugin(ScrollTrigger)
    const {
        background_mobile,
        background,
        pro_name,
        text_id,
        learn_link,
        learn_link_out,//如果learnlink是外链 则不用useNavigate
        buy_link,
        pro_key,
        buy_link_out, //buylink 则不用useNavigate
        theme
    } = intro
    const enabled = pro_name == 'AIR 2 PRO' ? useFeatureIsOn("shop-style-show") : false;
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const proIntroRef = useRef()
    const proBoxRef = useRef()


    const goLearnLink = () => {

        if (!!learn_link_out) {
            window.open(learn_link)
        } else {
            useNavigate(learn_link)
        }
        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: 20066,
                product_name: intro.pro_key
            }
        });
    }

    const goBuyLink = () => {
        if (!!enabled && pro_name == 'AIR 2 PRO') {
            window.open("https://us.shop.xreal.com/products/xreal-air-2-pro?fw=official_buynowB")
        } else if (pro_name == 'AIR 2 PRO') {
            window.open("https://us.shop.xreal.com/products/xreal-air-2-pro?fw=official_buynowA")
        } else {
            if (!!buy_link_out) {
                window.open(buy_link)
            } else {
                useNavigate(buy_link)
            }
        }

        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: 20067,
                product_name: intro.pro_key
            }
        });

    }

    useEffect(() => {
        if (pro_key == 'beampro') {
            gsap.to(proIntroRef.current, {
                opacity: 1,
                bottom: '64px',
                duration: 0.6,

            })
        } else {
            gsap.to(proIntroRef.current, {
                opacity: 1,
                bottom: '64px',
                duration: 0.5,
                scrollTrigger: {
                    start: "bottom bottom",
                    end: "bottom bottom",
                    trigger: proBoxRef.current,

                }
            })
        }


    }, [])




    return (
        <div className={style.probox} ref={proBoxRef}>
            <div className={style.proboxImg} style={{ backgroundImage: `url(${ifPc ? background : background_mobile})`, cursor: !!learn_link ? 'pointer' : 'auto' }} onClick={() => {
                !!learn_link && goLearnLink()
            }}></div>
            <div className={style.proboxContent}
                ref={proIntroRef}
                style={{ color: theme }}
            >
                <div className={style.proboxContentTitle}>{pro_name}</div>
                <div className={style.proboxContentDesc}>
                    {intl.formatMessage({ id: text_id })}
                </div>
                <div className={style.proboxContentBtn}>
                    {!!learn_link && <div className={style.proboxContentBtnItem}
                        onClick={goLearnLink}
                    >
                        {intl.formatMessage({ id: "learn_more" })}
                    </div>}
                    {!!buy_link && <div className={style.proboxContentBtnItem}
                        onClick={goBuyLink}
                    >
                        {intl.formatMessage({ id: "buy_now" })}
                    </div>}
                    {!!enabled && pro_name == 'AIR 2 PRO' && <div className={style.proboxContentBtnItem}
                        onClick={() => window.open('https://www.amazon.com/dp/B0CHVQWW4P?maas=maas_adg_1D3D268476729A94B4AC0BBAB06B8FDC_afap_abs&ref_=aa_maas&tag=maas')}
                    >
                        SHOP ON <img src={AmazonIcon} />
                    </div>}
                </div>
            </div>
        </div>
    )
}
export default ABTest

