
const ada_device_ios = `${process.env.resourceUrl}images/adapter/iphone-s.webp`
const ada_device_ps = `${process.env.resourceUrl}images/adapter/ps-s.webp`
const ada_device_xbox = `${process.env.resourceUrl}images/adapter/xbox-s.webp`
const ada_device_switch = `${process.env.resourceUrl}images/adapter/switch-s.webp`



// jp

const ada_banner1_jp = `${process.env.resourceUrl}images/adapter/jp/banner1.jpg`
const ada_banner2_jp = `${process.env.resourceUrl}images/adapter/jp/banner2.jpg`
const ada_use1_jp = `${process.env.resourceUrl}images/adapter/jp/use1.jpg`
const ada_use2_jp = `${process.env.resourceUrl}images/adapter/jp/use2.jpg`
const ada_view1_jp = `${process.env.resourceUrl}images/adapter/jp/view1.jpg`
const ada_view2_jp = `${process.env.resourceUrl}images/adapter/jp/view2.jpg`
const ada_view3_jp = `${process.env.resourceUrl}images/adapter/jp/view3.jpg`
const ada_view4_jp = `${process.env.resourceUrl}images/adapter/jp/view4.jpg`
const ada_view5_jp = `${process.env.resourceUrl}images/adapter/jp/view5.jpg`
const ada_view6_jp = `${process.env.resourceUrl}images/adapter/jp/view6.jpg`
const ada_view7_jp = `${process.env.resourceUrl}images/adapter/jp/view7.jpg`

import AdaHeader1 from './ada-header-1.svg'
import AdaHeader2 from './ada-header-2.svg'
import AdaHeader1_black from './AdaHeader1Black.svg'
import AdaHeader2_black from './AdaHeader2Black.svg'
import Reddit from './reddit.png'


const proBg_ada_pc = `${process.env.resourceUrl}images/adapter/proBg-ada-pc.webp`
const proBg_ada_mobile = `${process.env.resourceUrl}images/adapter/proBg-ada-mobile.webp`
const pro_ada_logo = `${process.env.resourceUrl}images/adapter/pro-ada-logo.svg`
const adaBuy = `${process.env.resourceUrl}images/adapter/adaBuy.webp`
const adaBuy_mobile = `${process.env.resourceUrl}images/adapter/adaBuy_mobile.webp`

export {
    adaBuy_mobile,
    adaBuy,
    pro_ada_logo,
    proBg_ada_pc,
    proBg_ada_mobile,
    AdaHeader1_black,
    AdaHeader2_black,
  

    ada_device_ios,
	ada_device_ps,
	ada_device_xbox,
	ada_device_switch,
    AdaHeader1,
    AdaHeader2,

    Reddit,
    ada_banner1_jp,
    ada_banner2_jp,
    ada_use1_jp,
    ada_use2_jp,
    ada_view1_jp,
    ada_view2_jp,
    ada_view3_jp,
    ada_view4_jp,
    ada_view5_jp,
    ada_view6_jp,
    ada_view7_jp,
   
}