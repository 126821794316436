import React from 'react'
import { useIntl } from 'react-intl'
import style from './index.module.less'

const SaleLogo = ({text})=>{
    const intl = useIntl()
    return(
        <div className={style.sale}>
            <span>SALE</span>
        </div>
    )
} 
export default SaleLogo