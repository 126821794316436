import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import { stickersVd } from '@/images/home/index'
import LearnBuy from "../learn-buy"
import StickerSvg from "@/images/SVG/KaleidoKit.svg"
import comcss from "../index.module.less"
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"


const Index = () => {
    gsap.registerPlugin(ScrollTrigger)
    const introRef = useRef(null)
    const blockRef = useRef(null)
    const countryCode = getCountry()
    const ifPc = useBigScreen(768)


    useEffect(() => {
        gsap.killTweensOf('.sticker-home');
        if (ifPc) {
         

            if (!!blockRef) {
                gsap.fromTo('.sticker-home', {
                    x: '100%',
                    duration:0.3
                },
                    {
                        x: 0,
                        scrollTrigger: {
                            trigger: blockRef.current,
                            start: "top center",
                            end: 'bottom top',
                            toggleActions: 'play reverse play reverse',
                        }
                    });
            }
        }
    }, [ifPc])
    return (
        <div className={`${style.block} ${comcss.section}`} ref={blockRef}>
            <div className={style.blockVideo}>
                <video autoPlay loop muted playsInline width="100%" >
                    <source src={stickersVd} type="video/mp4" />
                </video>
            </div>
            <div className={`${comcss.buy} ${ifPc?style.blockBuy:style.blockBuyM} sticker-home`} ref={introRef}>
                <LearnBuy icon={<StickerSvg />} theme={"white"} textid={"home.stickerdes"} buylink={buy_link[countryCode]['sticker'].shop || buy_link[countryCode]['sticker'].amazon} proName='sticker' />
            </div>




        </div>
    )
}

export default Index