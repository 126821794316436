import React, { useCallback, useState, useEffect } from 'react';

function useWinSize() {
  const [size, setSize] = useState({
    width: typeof window === 'undefined' || !window.document ? '' : window.innerWidth,
    height: typeof window === 'undefined' || !window.document ? '' : window.innerHeight
  })

  const onResize = useCallback(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return size;
}

export default useWinSize;