import React, { useEffect, useRef, useState } from "react";
import style from './index.module.less'
import { useIntl } from "react-intl"
import useWinSize from "@/hooks/useWinSize"
import getObjectFitSize from "@/utils/getObjFitSize"
import comcss from "../index.module.less"
const loadingbg = `${process.env.resourceUrl}images/home/webp/shouping_0000.webp`
const isBrowser = typeof window !== "undefined" && window

let frameCount = 175;
const images = [];
for (let i = 0; i < frameCount; i++) {
    const paddedIndex = i.toString().padStart(4, '0');
    images[i] = `${process.env.resourceUrl}images/home/webp/shouping_${paddedIndex}.webp`;
}

function preloadImages(urls) {
    const promises = urls.map((url) => {
        return new Promise((resolve, reject) => {
            const image = isBrowser && new Image()
            image.src = url;
            image.onload = () => resolve(image);
            image.onerror = () => reject(`Image failed to load: ${url}`);
        });
    });
    return Promise.all(promises);
}


const Index = ({ }) => {
    const size = useWinSize()
    const intl = useIntl()
    const [curIdx, setCurIdx] = useState(0)
    const bgRef = useRef(null)
    const boxRef = useRef(null)
    const handleScroll = () => {
        if (!!boxRef.current) {
            const boxTop = boxRef.current.offsetTop
            const boxBottom = boxRef.current.offsetHeight + boxRef.current.clientHeight - window.innerHeight

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;


            if (scrollTop < boxTop) {
                setCurIdx(1);
                requestAnimationFrame(() => {
                    updateImage(1);
                    updateText(1);
                });
            } else if (scrollTop > boxBottom) {
                setCurIdx(frameCount - 1);
                requestAnimationFrame(() => {
                    updateImage(frameCount - 1);
                    updateText(frameCount - 1);
                });
            }
            else {
                const scrollFraction = (scrollTop - boxTop) / (window.innerHeight - 1); // 减去 1 避免浮点数精度问题
                const frameIndex = Math.min(frameCount - 1, Math.floor(scrollFraction * frameCount));
                setCurIdx(frameIndex);
                requestAnimationFrame(() => {
                    updateImage(frameIndex);
                    updateText(frameIndex);
                });
            }
        }

    }


    const [imagesAry, setImagesAry] = useState([])
    useEffect(() => {
        let imgAry = []
        const fetchData = async () => {
            imgAry = await preloadImages(images);
            setImagesAry(imgAry);
        }
        fetchData()


    }, [])

    useEffect(() => {
        if (imagesAry.length > 0) {
            updateImage(curIdx)
            updateText(curIdx)
            window.addEventListener("scroll", handleScroll)
        }
        else {
            const [text1C, text2C, text1h2C] = [text1.current, text2.current, text1h2.current];
            text1C.style.opacity = 1
            text2C.style.opacity = 0
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [size, imagesAry])




    const text1 = useRef(null)
    const text2 = useRef(null)
    const text1h2 = useRef(null)
    const updateText = (index) => {
        // 第1帧到第44帧    第45帧到第84帧 第85帧到最后
        let showAry = [1, 44, 45, 60, 61, 84, 85, frameCount]
        const [text1C, text2C, text1h2C] = [text1.current, text2.current, text1h2.current];
        if (index <= showAry[1]) {
            text1C.style.opacity = 1
            text2C.style.opacity = 0
            text1h2C.style.transform = `scale(1)`
        } else if (index > showAry[1] && index <= showAry[3]) {
            text1C.style.opacity = 1
            text2C.style.opacity = 0
            text1h2C.style.transform = `scale(1)`
        } else if (index > showAry[3] && index <= showAry[5]) {

            let total = showAry[5] - showAry[3]
            let dis_01 = index - showAry[3]
            let dis_10 = showAry[5] - index
            let percent01 = dis_01 / total
            let percent10 = dis_10 / total

            text1C.style.opacity = 1 - percent01 * 1
            text2C.style.opacity = 0
            text1h2C.style.transform = `scale(${0.5 + 0.5 * percent10})`
        } else {
            let total = 10
            let dis = index - showAry[5]
            let opacity = dis / total

            text1C.style.opacity = 0
            text2C.style.opacity = opacity > 1 ? 1 : opacity
        }
    }
    const updateImage = (index) => {
        let canvas = bgRef.current
        let ctx = canvas.getContext("2d");
        const { sx, sy, swidth, sheight, x, y, width, height } = getObjectFitSize('cover', size.width, size.height, 2560, 1440)
        ctx.drawImage(imagesAry[index], sx, sy, swidth, sheight, 0, 0, width, height);
    }
    return (
        <>

            <div className={style.block}
                style={{ height: `${imagesAry.length <= 0 ? `${size.height}px` : `${size.height * 2}px`}` }}
                ref={boxRef}
            >
                <div className={style.blockBg} style={{ backgroundImage: `url(${loadingbg})`, height: `${size.height}px` }}>
                    <canvas ref={bgRef}
                        width={size.width}
                        height={size.height} />
                    <div className={style.blockBgText} ref={text1}>
                        <h2
                            className={comcss.title}
                            ref={text1h2} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.banner.t1" }) }}></h2>
                    </div>
                    <div className={style.blockBgText} ref={text2}>
                        <h2
                            className={comcss.title}
                            dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "home.banner.t2" }) }}></h2>
                    </div>
                </div>



            </div>

        </>
    )
}

export default Index