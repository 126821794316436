import airLogo from './airLogo.svg'
import comfortLogo1 from './comfortLogo1.svg'
import comfortLogo2 from './comfortLogo2.svg'
import howtouseNo from './howtouseNo.svg'
import howtouseAda from './howtouseAda.svg'
import howtouseMac from './howtouseMac.svg'
import howtouseBeam from './howtouseBeam.svg'
import howtouseDP from './howtouseDP.svg'
import howtouseAndroid from './howtouseAndroid.svg'
import GoLinkNew from './SVG/goLinkNew.svg'
import reddit from './reddit.png'
import ins from './ins.svg'
import amazon from './amazon.svg'
import twitter from './twitter.svg'
import youtube from './youtube.svg'


const proBgAir_pc = `${process.env.resourceUrl}images/air/proBg-air-pc.webp`
const proBgAir_mobile = `${process.env.resourceUrl}images/air/proBg-air-mobile.webp`
const proAir_logo = `${process.env.resourceUrl}images/air/pro-air-logo.svg`
const proAir_sale_logo = `${process.env.resourceUrl}images/air/pro-air-logo-sale.svg`
const airBuyPro = `${process.env.resourceUrl}images/air/airBuy.webp`
const airBuyPro_mobile = `${process.env.resourceUrl}images/air/airBuy_mobile.webp`
const home_air_pc = `${process.env.resourceUrl}images/air/home-air-pc.webp`
const home_air_mobile = `${process.env.resourceUrl}images/air/home-air-mobile.webp`
const air_video_game = `${process.env.resourceUrl}video/air_game.mp4`
const air_video_movie = `${process.env.resourceUrl}video/air_movie.mp4`

const buyAll_air = `${process.env.resourceUrl}images/air/buyAll_air.webp`
const buyAll_air_ada = `${process.env.resourceUrl}images/air/buyAll_air_ada.webp`
const buyAll_air_beam = `${process.env.resourceUrl}images/air/buyAll_air_beam.webp`
const buy_air_openBox = `${process.env.resourceUrl}images/air/buy_air_openBox.webp`
const accessories1_1 = `${process.env.resourceUrl}images/air/accessories1_1.webp`
const accessories1_2 = `${process.env.resourceUrl}images/air/accessories1_2.webp`
const accessories2_1 = `${process.env.resourceUrl}images/air/accessories2_1.webp`
const accessories2_2 = `${process.env.resourceUrl}images/air/accessories2_2.webp`
const accessories3_1 = `${process.env.resourceUrl}images/air/accessories3_1.webp`
const accessories3_2 = `${process.env.resourceUrl}images/air/accessories3_2.webp`
const accessories4_1 = `${process.env.resourceUrl}images/air/accessories4_1.webp`
const accessories4_2 = `${process.env.resourceUrl}images/air/accessories4_2.webp`
const air_picscroll_1 = `${process.env.resourceUrl}images/air/air_picscroll_1.webp`
const air_picscroll_2 = `${process.env.resourceUrl}images/air/air_picscroll_2.webp`
const air_picscroll_3 = `${process.env.resourceUrl}images/air/air_picscroll_3.webp`
const air_picscroll_4 = `${process.env.resourceUrl}images/air/air_picscroll_4.webp`
const air_picscroll_5 = `${process.env.resourceUrl}images/air/air_picscroll_5.webp`
const air_picscroll_6 = `${process.env.resourceUrl}images/air/air_picscroll_6.webp`


export {
    air_picscroll_1,
    air_picscroll_2,
    air_picscroll_3,
    air_picscroll_4,
    air_picscroll_5,
    air_picscroll_6,
    accessories1_1,
    accessories1_2,
    accessories2_1,
    accessories2_2,
    accessories3_1,
    accessories3_2,
    accessories4_1,
    accessories4_2,
    buy_air_openBox,
    buyAll_air,
    buyAll_air_ada,
    buyAll_air_beam,
    proAir_sale_logo,
    home_air_pc,
    home_air_mobile,
    airBuyPro_mobile,
    airBuyPro,
    proAir_logo,
    proBgAir_mobile,
    proBgAir_pc,
    air_video_game,
    air_video_movie,
    airLogo,
    comfortLogo1,
    comfortLogo2,
    howtouseNo,
    howtouseAda,
    howtouseMac,
    howtouseBeam,
    howtouseDP,
    howtouseAndroid,
    GoLinkNew,
    youtube,
    twitter,
    amazon,
    ins,
    reddit
}