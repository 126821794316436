const specs_air2pro = `${process.env.resourceUrl}images/air2/specs_air2pro.webp`
const specs_air2 = `${process.env.resourceUrl}images/air2/specs_air2.webp`
const specs_air = `${process.env.resourceUrl}images/air2/specs_air.webp`
import Air2Black from './air2Black.svg'
import Air2ProBlack from './air2proBlack.svg'



const proBg_air2_pc = `${process.env.resourceUrl}images/air2-1024/proBg-air2-pc.webp`
const proBg_air2_pc_right = `${process.env.resourceUrl}images/air2-1024/proBg-air2-pc-right.webp`
const proBg_air2_mobile = `${process.env.resourceUrl}images/air2-1024/proBg-air2-mobile.webp`
const pro_air2_logo = `${process.env.resourceUrl}images/air2-1024/pro-air2-logo.svg`
const certificated_bg = `${process.env.resourceUrl}images/air2-1024/certificated_bg.webp`
const certificated1 = `${process.env.resourceUrl}images/air2-1024/certificated1.svg`
const certificated2 = `${process.env.resourceUrl}images/air2-1024/certificated2.svg`
const upgrade1 = `${process.env.resourceUrl}images/air2-1024/upgrade1.webp`
const upgrade2 = `${process.env.resourceUrl}images/air2-1024/upgrade2.webp`
const upgrade3 = `${process.env.resourceUrl}images/air2-1024/upgrade3.webp`
const beauty_under = `${process.env.resourceUrl}images/air2-1024/beauty_under.webp`
const beauty_up = `${process.env.resourceUrl}images/air2-1024/beauty_up.webp`
const air2ProBuy = `${process.env.resourceUrl}images/air2-1024/air2ProBuy.webp`
const air2ProBuy_mobile = `${process.env.resourceUrl}images/air2-1024/air2proBuy_mobile.webp`
const air2Buy = `${process.env.resourceUrl}images/air2-1024/air2Buy.webp`
const air2Buy_mobile = `${process.env.resourceUrl}images/air2-1024/air2Buy_mobile.webp`

const cinematicSoundL = `${process.env.resourceUrl}images/air2-1024/cinematicSoundL.webp`
const cinematicSoundR = `${process.env.resourceUrl}images/air2-1024/cinematicSoundR.webp`
const lifeLikeColor = `${process.env.resourceUrl}images/air2-1024/lifeLikeColor.webp`
const immersionVideo = `${process.env.resourceUrl}video/air2-immersion.mp4`

const micro_bg3 = `${process.env.resourceUrl}images/air2-1024/micro_bg3.webp`
const engineBg = `${process.env.resourceUrl}images/air2-1024/engine_bg.webp`

const displayMode_glasses = `${process.env.resourceUrl}images/air2-1024/displayMode_glasses.svg`
const displayMode_beam = `${process.env.resourceUrl}images/air2-1024/displayMode_beam.svg`
const displayMode_add = `${process.env.resourceUrl}images/air2-1024/displayMode_add.svg`

const lense_air2_white = `${process.env.resourceUrl}images/air2-1024/lense_air2.webp`
const lense_air2_logo1 = `${process.env.resourceUrl}images/air2-1024/lense_air2_1.png`
const lense_air2_logo2 = `${process.env.resourceUrl}images/air2-1024/lense_air2_2.png`
const lense_air2_logo3 = `${process.env.resourceUrl}images/air2-1024/lense_air2_3.png`
const env_air2_1 = `${process.env.resourceUrl}images/air2-1024/env-air2-1.webp`
const env_air2_2 = `${process.env.resourceUrl}images/air2-1024/env-air2-2.webp`
const env_air2_3 = `${process.env.resourceUrl}images/air2-1024/env-air2-3.webp`
const env_air2_4 = `${process.env.resourceUrl}images/air2-1024/env-air2-4.webp`
const env_air2_5 = `${process.env.resourceUrl}images/air2-1024/env-air2-5.webp`
const env_air2_6 = `${process.env.resourceUrl}images/air2-1024/env-air2-6.webp`
const env_air2_7 = `${process.env.resourceUrl}images/air2-1024/env-air2-7.webp`
const env_air2_8 = `${process.env.resourceUrl}images/air2-1024/env-air2-8.webp`
const env_air2_9 = `${process.env.resourceUrl}images/air2-1024/env-air2-9.webp`
const env_air2_10 = `${process.env.resourceUrl}images/air2-1024/env-air2-10.webp`
const picWindows = `${process.env.resourceUrl}images/device/pic-window.png`
const picMac = `${process.env.resourceUrl}images/device/pic-mac.webp`
const picAndroid = `${process.env.resourceUrl}images/device/pic-android.webp`
const picIpad = `${process.env.resourceUrl}images/device/pic-ipad_iphone.webp`
const picSteam = `${process.env.resourceUrl}images/device/pic-steam.webp`
const picSwitch = `${process.env.resourceUrl}images/device/pic-switch.webp`
const picPS5 = `${process.env.resourceUrl}images/device/pic-ps5.webp`
const picXbox = `${process.env.resourceUrl}images/device/pic-xbox.webp`
const picP55 = `${process.env.resourceUrl}images/device/pic-p55.webp`
const gaming_glass = `${process.env.resourceUrl}images/air2-1024/gaming-glass.webp`
const gaming_ally = `${process.env.resourceUrl}images/air2-1024/gaming-ally.webp`
const gaming_xbox = `${process.env.resourceUrl}images/air2-1024/gaming-xbox.webp`
const gaming_ps5 = `${process.env.resourceUrl}images/air2-1024/gaming-ps5.webp`
const gaming_steam = `${process.env.resourceUrl}images/air2-1024/gaming-steam.webp`
const gaming_switch = `${process.env.resourceUrl}images/air2-1024/gaming-switch.webp`
import air2ComingLogo from './icon/banner-air2.svg'
const displayVideo1 = `${process.env.resourceUrl}video/displayModes/d1.mp4`
const displayVideo2 = `${process.env.resourceUrl}video/displayModes/d2.mp4`
const displayVideo3 = `${process.env.resourceUrl}video/displayModes/d3.mp4`
const displayVideo4 = `${process.env.resourceUrl}video/displayModes/d4.mp4`
const immersion_level1 = `${process.env.resourceUrl}images/air2-1024/immersion_level1.png`
const immersion_level2 = `${process.env.resourceUrl}images/air2-1024/immersion_level2.png`
const immersion_level3 = `${process.env.resourceUrl}images/air2-1024/immersion_level3.png`
const beamApp_video = `${process.env.resourceUrl}video/beamApp.mp4`
const beamAppInstall_video = `${process.env.resourceUrl}video/beamAppInstall.mp4`


export {
    proBg_air2_pc_right,
    beamApp_video,
    beamAppInstall_video,
    immersion_level1,
    immersion_level2,
    immersion_level3,
    air2ComingLogo,
    picWindows,
    picMac,
    picAndroid,
    picIpad,
    picPS5,
    picSteam,
    picSwitch,
    picXbox,
    picP55,
    gaming_glass,
    gaming_ally,
    gaming_xbox,
    gaming_steam,
    gaming_switch,
    gaming_ps5,
    env_air2_1,
    env_air2_2,
    env_air2_3,
    env_air2_4,
    env_air2_5,
    env_air2_6,
    env_air2_7,
    env_air2_8,
    env_air2_9,
    env_air2_10,
    lense_air2_logo1,
    lense_air2_logo2,
    lense_air2_logo3,
    lense_air2_white,
    lifeLikeColor,
    cinematicSoundL,
    cinematicSoundR,
    immersionVideo,
    air2ProBuy,
    air2Buy,
    beauty_up,
    beauty_under,
    upgrade1,
    upgrade2,
    upgrade3,
    certificated1,
    certificated2,
    certificated_bg,
    proBg_air2_pc,
    proBg_air2_mobile,
    pro_air2_logo,



    specs_air2pro,
    specs_air2,
    specs_air,
    Air2Black,
    Air2ProBlack,
   
    micro_bg3,
    engineBg,
    
    displayMode_glasses,
    displayMode_beam,
    displayMode_add,
    air2Buy_mobile,
    air2ProBuy_mobile,
    displayVideo1,
    displayVideo2,
    displayVideo3,
    displayVideo4
}